<template>
  <div class="page-tool">
    <div class="page-tool-item" @click="returnHome">
      <svg-icon icon-class="home" />
    </div>
    <div class="page-tool-item" @click="returnTop">
      <svg-icon icon-class="return-top" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    returnHome () {
      let url = window.location.href
      if (url.indexOf('project') != -1 || url.indexOf('group') != -1) {
        this.$router.push({ name: 'ProjectPage' })
      } else if (url.indexOf('mine') != -1) {
        this.$router.push({ name: 'MinePage' })
      } else {
        this.$router.push({ name: 'HomePage' })
      }
    },
    returnTop () {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    }
  }
}
</script>

<style>
.page-tool {
  width: 2rem;
  height: 4rem;
  position: fixed;
  right: 1rem;
  bottom: 5rem;
}

.page-tool-item {
  width: 2rem;
  height: 2rem;
  font-size: 1.5rem;
  line-height: 2rem;
  background-color:rgba(255,255,255,0.5); 
  border-radius: 50%;
  text-align: center;
}
</style>
