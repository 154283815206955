import Vue from 'vue'
import App from './App'
import Vuex from 'vuex'
import router from './router'
import Vant, { Lazyload, Notify, Dialog } from 'vant'
import BaiduMap from 'vue-baidu-map'
import 'vant/lib/index.css'
import axios from 'axios'
import store from '@/store'
import WechatTitle from 'vue-wechat-title'
import '@/icons'
import '@/style/global.css'
import '@/style/article.css'
import VueSilentbox from 'vue-silentbox'
import AppConfig from '@/config'

//import { setToken } from '@/utils/token'
//import { setStorage } from '@/utils/storage'

Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.prototype.$appConfig = AppConfig
Vue.use(Vant)
Vue.use(Lazyload)
Vue.use(Notify)
Vue.use(Dialog)
Vue.use(Vuex)
Vue.use(WechatTitle)
Vue.use(BaiduMap, {
  ak: 'G2nHNFhrLuXLBIGEsxzlkXHvpgPL1mfu'
})
Vue.use(VueSilentbox)

/*
if (process.env.NODE_ENV === "development") {
  console.log("dev mode")
  setToken(AppConfig.devToken)
  setStorage('name', 'DEV')
  setStorage('avatar', 'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTL7o18p07vu5ibj841GuuIfRYy2X7frRN7DX7FftxlwZaVvk1P4I6yy10u7dDxJrFvSfv7pNia1MrMw/132')
} else {
  console.log("prod mode")
}*/


/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
