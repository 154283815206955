<template>
  <div id="app">
    <keep-alive>
      <router-view
        v-if="$route.meta.keepAlive"
        v-wechat-title="$route.meta.title"
      >
      </router-view>
    </keep-alive>
    <router-view
      v-if="!$route.meta.keepAlive"
      v-wechat-title="$route.meta.title"
    >
    </router-view>
    <page-tool
      v-if="
        !['HomePage', 'ProjectPage', 'MinePage', 'OrderPage'].includes(
          $route.name
        )
      "
    />
  </div>
</template>

<script>
import PageTool from "@/components/PageTool";
export default {
  name: "App",
  components: { PageTool },
  mounted() {
    const oHtml = document.getElementsByTagName("html")[0];
    const width = oHtml.clientWidth;
    oHtml.style.fontSize = 16 * (width / 414) + "px";
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
a {
  color: #2c3e50;
  text-decoration: none;
}
body {
  background-color: #f7f7f7;
}
</style>
