import Vue from 'vue'
import Router from 'vue-router'
import { getToken } from '@/utils/token'
//import wxSdk from '@/utils/wxsdk'
//import { getStorage } from '@/utils/storage'

Vue.use(Router)

const router = new Router({
  base: '/m/',
  mode: 'history',
  routes: [{
    path: '/',
    name: 'IndexPage',
    component: () => import('@/views/Index')
  }, {
    path: '/oauth',
    name: 'OauthHandle',
    component: () => import('@/views/OauthHandle'),
    meta: {
      title: '用户登录'
    }
  }, {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/views/Logout')
  },
  {
    path: '/home',
    name: 'HomePage',
    component: () => import('@/views/Home'),
    meta: {
      title: '首页 - 山东理工大学教育发展基金会'
    }
  },
  {
    path: '/project',
    name: 'ProjectPage',
    component: () => import('@/views/Project'),
    meta: {
      title: '项目 - 山东理工大学教育发展基金会'
    }
  },
  {
    path: '/mine',
    name: 'MinePage',
    component: () => import('@/views/Mine'),
    meta: {
      title: '我的 - 山东理工大学教育发展基金会'
    }
  },
  {
    path: '/project/:pid',
    redirect: '/project/:pid/project'
  },
  {
    path: '/project/:pid/:tab',
    name: 'ProjectDetail',
    component: () => import('@/views/ProjectDetail'),
    meta: {
      title: '项目详情'
    }
  },
  {
    path: '/group/:gid',
    name: 'GroupDonate',
    component: () => import('@/views/GroupDonate'),
    meta: {
      title: '捐赠团队'
    }
  },
  {
    path: '/donate/form',
    name: 'DonateForm',
    component: () => import('@/views/DonateForm'),
    meta: {
      title: '捐赠表单',
      keepAlive: true
    }
  },
  {
    path: '/test',
    name: 'TestPage',
    component: () => import('@/views/TestPage')
  },
  {
    path: '/order/:order_no',
    name: 'OrderPage',
    component: () => import('@/views/Order'),
    meta: {
      title: '在线支付 - 山东理工大学教育发展基金会'
    }
  },
  {
    path: '/article/:article_id',
    name: 'ArticlePage',
    component: () => import('@/views/Article'),
    meta: {
      title: '山东理工大学教育发展基金会'
    }

  },
  {
    path: '/donate/:donate_no',
    name: 'DonateSuccess',
    component: () => import('@/views/DonateSuccess'),
    meta: {
      title: '捐赠成功'
    }

  },
  {
    path: '/newgroup',
    name: 'CreateGroup',
    component: () => import('@/views/CreateGroup'),
    meta: {
      title: '创建团队'
    }
  },
  {
    path: '/editgroup/:group',
    name: 'EditGroup',
    component: () => import('@/views/EditGroup'),
    meta: {
      title: '编辑团队'
    }
  },
  {
    path: '/mine/donate',
    name: 'MyDonate',
    component: () => import('@/views/MyDonate'),
    meta: {
      title: '捐赠记录'
    }
  },
  {
    path: '/mine/cert',
    name: 'MyCert',
    component: () => import('@/views/MyCert'),
    meta: {
      title: '我的证书'
    }
  },
  {
    path: '/mine/group',
    name: 'MyGroup',
    component: () => import('@/views/MyGroup'),
    meta: {
      title: '我的团队'
    }
  },
  {
    path: '/mine/profile',
    name: 'MyProfile',
    component: () => import('@/views/Profile'),
    meta: {
      title: '个人信息'
    }
  },
  {
    path: '/mine/address',
    name: 'MyAddress',
    component: () => import('@/views/Address'),
    meta: {
      title: '我的地址'
    }
  },
  {
    path: '/version',
    name: 'Version',
    component: () => import('@/views/Version'),
    meta: {
      title: '当前版本'
    }
  },
  {
    path: '/page/:page',
    name: 'PagePage',
    component: () => import('@/views/Page'),
    meta: {
      title: '页面'
    }
  },
  { path: '*', name: 'ErrorPage', component: () => import('@/views/Error') }
  ]
})

router.beforeEach((to, from, next) => {
  if (!['OauthHandle', 'OrderPage', 'TestPage'].includes(to.name) && !getToken()) {
    next({ name: 'OauthHandle', query: { redirect: to.fullPath } })
  } else {
    /*
    if (getToken() && !['ProjectDetail', 'ArticlePage', 'MyAddress'].includes(to.name)) {
      wxSdk.share({
          title: to.meta.title,
          desc: getStorage('name') + '邀请您查看',
          link: window.location.href
        })
    } */
    next()
  }
})

export default router
