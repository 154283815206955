import request from '@/utils/request'

export function oauthRedirect (redirectUrl) {
  return request({
    url: '/oauth',
    method: 'get',
    params: { redirect: redirectUrl }
  })
}

export function oauthCallback (code) {
  return request({
    url: '/oauth_callback',
    method: 'get',
    params: { code: code }
  })
}
