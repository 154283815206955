import axios from 'axios'
import { getToken, removeToken } from '@/utils/token'
import { Toast } from 'vant'
import AppConfig from '@/config'

const service = axios.create({
  baseURL: 'https://api.isdut.cn/api/wxd',
  // withCredentials: true,
  timeout: 5000
})

service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers['token'] = getToken()
      config.headers['version'] = AppConfig.version
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.status !== 200) {
      console.log(res.message)

      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    if (error.response.status === 401) {
      removeToken()
      window.location.reload()
    } else {
      let message = null
      if (error.response.data.message) {
        message = error.response.data.message
      } else {
        message = error.message
      }
      Toast({
        message: '错误: ' + message,
        position: 'bottom'
      })
    }
    return Promise.reject(error)
  }
)

export default service
